import { BudgetAllocationResponse, CostCenterBodyResponse } from '@models/CostCenters'
import { BudgetAllocation, Form } from '../types'
import { BudgetFrequency, DeductedSumType } from '@type/budget'
import { Currencies } from '@type/currency'
import { v4 } from 'uuid'
import { TableHelper } from '../components/EditableTable/utils/TableHelper'
import { getYear } from 'date-fns'

export class FormState implements Form {
  budgetAllocations: BudgetAllocation[]
  code: string
  currency: Currencies
  deductedSum: DeductedSumType
  filter: { year?: number | undefined }
  frequency: BudgetFrequency
  name: string
  owner?: number | undefined
  startDay: number

  private getBudget = (budget: BudgetAllocationResponse, groupId: string): BudgetAllocation => {
    return {
      startDate: new Date(budget.start_date),
      endDate: new Date(budget.end_date),
      name: budget.name,
      amount: (budget.amount || 0) / 100,
      category: budget.accounting_category?.id,
      id: v4(),
      groupId,
      isUncategorized: !budget.accounting_category?.id,
    }
  }

  private getBudgets = (budgets?: BudgetAllocationResponse[]): BudgetAllocation[] => {
    let budgetAllocations: BudgetAllocation[] = []

    if (budgets?.length) {
      budgetAllocations = budgets.map((budget) => {
        let amount = budget.amount
        if (!budget.accounting_category) {
          amount = budgets.reduce(
            (sum, sumBudget) => (sumBudget.start_date === budget.start_date ? sum + (sumBudget.amount || 0) : sum),
            0,
          )
        }
        return this.getBudget({ ...budget, amount }, budget.accounting_category?.name || 'Uncategorized')
      })
    } else {
      const tableHelper = new TableHelper('')
      budgetAllocations = tableHelper.getNewCategoryPeriods(this.frequency, this.startDay, getYear(new Date()), true)
    }

    return budgetAllocations
  }

  constructor(data?: CostCenterBodyResponse, currency?: Currencies) {
    this.name = data?.name || ''
    this.code = data?.code || ''
    this.owner = data?.owner
    this.deductedSum = data?.deducted_sum || DeductedSumType.gross
    this.frequency = data?.budget_frequency || BudgetFrequency.monthly
    this.startDay = data?.starting_day || 1
    this.currency = currency || Currencies.USD
    this.filter =
      data?.budget_frequency !== BudgetFrequency.yearly
        ? {
            year: new Date().getFullYear(),
          }
        : {}
    this.budgetAllocations = this.getBudgets(data?.budget_allocations)
  }
}
