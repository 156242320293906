import React, { useCallback, useMemo } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  ColumnDef,
  getGroupedRowModel,
  getExpandedRowModel,
  FilterFn,
  getFilteredRowModel,
  getSortedRowModel,
  SortingFn,
} from '@tanstack/react-table'
import cx from 'clsx'
import { format, isWithinInterval } from 'date-fns'

import { IconCirclePlus } from '@tabler/icons-react'
import Category from './components/Category'
import Actions from './components/Actions'
import Select, { useSelectHelpers } from '@components/Form/ReactSelect'
import Amount from './components/Amount'

import { useCostCenterContext } from '../../utils'
import { FilterYear } from '../../../utils/constants'
import { useTableHelpers } from './utils/TableHelper'
import { createColumn } from '@helpers/react-table'

import { BudgetAllocation, GlobalBudgetFilter } from '../../types'
import { BudgetFrequency } from '@type/budget'
import { SingleValue } from 'react-select'

const columnHelper = createColumnHelper<BudgetAllocation>()

type ColumnMeta = {
  totalAccessor: number
}

export interface EditableTableProps {}

interface SortingFns {
  uncategorizedFirst: SortingFn<BudgetAllocation>
}

const yearFilter: FilterFn<BudgetAllocation> = (row, _, filterOption: GlobalBudgetFilter) => {
  if (!filterOption.year) {
    return true
  }
  const year = row.original.startDate.getUTCFullYear()
  return year === filterOption.year
}

const uncategorizedFirst: SortingFn<BudgetAllocation> = (a) => {
  return a.original.isUncategorized ? 1 : -1
}

const grouping = ['groupId']
const sorting = [{ id: 'groupId', desc: true }]

export const EditableTable: React.FC<EditableTableProps> = () => {
  const tableHelpers = useTableHelpers()

  const { setFieldValue, values } = useCostCenterContext()

  const { frequency, startDay, filter } = values

  const columns = useMemo(() => {
    const amountColumns: ColumnDef<BudgetAllocation, number>[] = tableHelpers.getPeriodColumns(frequency)

    return [
      columnHelper.accessor(
        'groupId',
        createColumn<BudgetAllocation, SortingFns>({
          header: 'Categories',
          cell: ({ row }) => {
            return row.original.isUncategorized ? (
              'Uncategorized'
            ) : (
              <Category value={row.original.category} year={filter.year} />
            )
          },
          sortingFn: 'uncategorizedFirst',
        }),
      ),
      ...amountColumns,
      columnHelper.accessor('name', {
        header: '',
        cell: Actions,
      }),
    ]
  }, [frequency, filter.year])

  const table = useReactTable({
    data: values.budgetAllocations,
    columns,
    state: {
      grouping,
      globalFilter: filter,
      sorting,
    },
    globalFilterFn: yearFilter,
    sortingFns: {
      uncategorizedFirst,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  })

  const handleAddRow = useCallback(() => {
    setFieldValue(`budgetAllocations`, [
      ...values.budgetAllocations,
      ...tableHelpers.getNewCategoryPeriods(frequency, values.startDay, values.filter.year),
    ])
  }, [values.budgetAllocations, values.frequency, values.startDay, values.filter])

  const { optionValue } = useSelectHelpers(FilterYear.options, values.filter.year)

  const handleSelectFilter = useCallback(
    async (option: SingleValue<Option<number>>) => {
      await setFieldValue('filter.year', option?.value)

      if (
        option?.value &&
        !values.budgetAllocations.filter(
          (budget) => budget.isUncategorized && budget.startDate.getUTCFullYear() === option.value,
        ).length
      ) {
        setFieldValue(`budgetAllocations`, [
          ...values.budgetAllocations,
          ...tableHelpers.getNewCategoryPeriods(frequency, values.startDay, option.value, true),
        ])
      }
    },
    [values.budgetAllocations.length],
  )

  return (
    <div>
      {frequency !== BudgetFrequency.yearly && (
        <div className="flex w-full sm:w-48">
          <Select
            options={FilterYear.options}
            value={optionValue}
            onChange={handleSelectFilter}
            className="!mb-1 w-full"
          />
        </div>
      )}
      <div className={cx('rounded-base bg-white max-w-full overflow-x-auto w-full rounded-b-none')}>
        <table className="border-spacing-0 h-[1px] w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <React.Fragment key={headerGroup.id}>
                  <tr className="rounded-t bg-slate-200">
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id} className="border-none py-1 px-2.5 text-left">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      )
                    })}
                  </tr>
                  <tr key={headerGroup.id + 'rowtotal'} className="bg-indigo-50">
                    {headerGroup.headers.map((header) => {
                      const meta = header.column.columnDef.meta as ColumnMeta | undefined
                      const hasTotal = !!meta && !isNaN(meta.totalAccessor)

                      let isHighlighted = false
                      let periodDate = new Date()

                      if (hasTotal) {
                        const { startDate, endDate } = tableHelpers.getPeriodDates(
                          meta?.totalAccessor,
                          frequency,
                          startDay,
                          values.filter.year || new Date().getUTCFullYear(),
                        )
                        periodDate = startDate

                        isHighlighted = isWithinInterval(new Date().setDate(1), {
                          start: startDate,
                          end: endDate,
                        })
                      }

                      const index = values.budgetAllocations.findIndex(
                        (budget) =>
                          budget.isUncategorized &&
                          format(periodDate, 'yyyy-MM-dd') === format(budget.startDate, 'yyyy-MM-dd'),
                      )

                      return (
                        <td
                          key={header.id + 'celltotal'}
                          className={cx('border-r last:border-none', {
                            'py-1 px-2.5': !hasTotal,
                            'p-[1px]': hasTotal,
                            'bg-purple-100': isHighlighted,
                          })}
                        >
                          {hasTotal && <Amount index={index} currencySymbol={tableHelpers.currencySymbol} isTotal />}
                          {header.index === 0 && <b>Total allocated</b>}
                        </td>
                      )
                    })}
                  </tr>
                </React.Fragment>
              )
            })}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <React.Fragment key={row.id}>
                  <tr
                    className={cx(
                      'first:border-t-none border-t h-full',
                      row.original.isUncategorized ? 'bg-indigo-50' : 'bg-white',
                    )}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const period = (cell.column.columnDef.meta as ColumnMeta)?.totalAccessor

                      let customClass = ''
                      if (typeof period === 'number') {
                        const start = cell.row.subRows?.[period]?.original.startDate
                        const end = cell.row.subRows?.[period]?.original.endDate
                        if (start && end) {
                          customClass = isWithinInterval(new Date().setDate(1), {
                            start,
                            end,
                          })
                            ? 'bg-purple-100'
                            : ''
                        }
                      }

                      return (
                        <td
                          key={`${cell.id}-${row.id}`}
                          className={cx(
                            'border-r last:border-none p-[1px] h-full',
                            cell.row.original.isUncategorized && 'px-2.5 py-1',
                            customClass,
                          )}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      )
                    })}
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
      <button
        className={cx(
          'w-full p-1.5 transition-colors bg-white text-center text-brand_secondary hover:text-brand_primary border-t rounded-b-base',
        )}
        type="button"
        onClick={handleAddRow}
      >
        <IconCirclePlus className="h-5 w-5 m-auto" />
      </button>
    </div>
  )
}

export default React.memo(EditableTable)
