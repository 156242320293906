import React, { useEffect, useMemo } from 'react'
import { generatePath, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isWithinInterval } from 'date-fns'

import Header from '@components/Header'
import LinkButton from '@components/LinkButton'

import routes from '@src/Routes/routes'
import { useGetCostCenterDetail } from '@queries/Accounting/costCenter'
import useGetUUIDFromPath from '@helpers/hooks/useGetUUIDFromPath'
import useStore from '@pages/Accounting/CostCenter/store'

import { BudgetFrequency } from '@type/budget'

export interface DetailsProps {}

export const Details: React.FC<DetailsProps> = () => {
  const { t } = useTranslation('common')
  const { id } = useGetUUIDFromPath()

  const { data: costCenterDetail } = useGetCostCenterDetail(id)
  const setFilter = useStore.use.setFilter()
  const setPeriodOptions = useStore.use.setPeriodOptions()

  const periodOptions = useMemo(() => {
    let availableBudgets = costCenterDetail?.budget_allocations

    if (costCenterDetail?.budget_frequency !== BudgetFrequency.custom) {
      availableBudgets = availableBudgets?.filter(
        (budget, index) =>
          costCenterDetail?.budget_allocations?.findIndex(
            (searchBudget) => searchBudget.start_date === budget.start_date,
          ) === index,
      )
    }

    return availableBudgets?.map(({ name: label, start_date: value }) => ({ label, value })) || []
  }, [costCenterDetail?.budget_frequency])

  useEffect(() => {
    if (periodOptions) {
      setPeriodOptions(periodOptions)
    }
  }, [periodOptions])

  useEffect(() => {
    const period = costCenterDetail?.budget_allocations?.find(({ start_date, end_date }) => {
      return isWithinInterval(new Date(), {
        start: new Date(start_date),
        end: new Date(end_date),
      })
    })
    setFilter('period', period?.start_date || costCenterDetail?.budget_allocations?.[0]?.start_date)
  }, [])

  return (
    <>
      <Header
        backUrl={routes.private.costCenter}
        title={costCenterDetail?.name}
        buttons={
          <LinkButton
            to={generatePath(routes.private.costCenterEditById, { id })}
            type="outlined"
            state={{
              backRoute: location.pathname,
            }}
          >
            {t('edit')}
          </LinkButton>
        }
      />
      <div className="base flex flex-col gap-3 p-4">
        <Outlet />
      </div>
    </>
  )
}

export default Details
