import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'clsx'

import { IconRocket } from '@tabler/icons-react'
import Button from '@components/Button'
import PlansListPopup from '@pages/Billing/PlansListPopup'

import { usePopupHandlers } from '@components/Popup/usePopupHandler'

export interface ActionButtonsProps {
  className?: string
  buttonClassName?: string
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ className, buttonClassName }) => {
  const { t } = useTranslation('paywalls')

  const { setIsPopupOpen, handleTogglePopup, isPopupOpen } = usePopupHandlers()

  return (
    <>
      {isPopupOpen && <PlansListPopup isPopupVisible={isPopupOpen} setIsPopupVisible={setIsPopupOpen} />}
      <div className={cx('flex gap-2 flex-col flex-shrink sm:flex-row', className)}>
        <Button onClick={handleTogglePopup} className={cx('sm:w-40', buttonClassName)}>
          <IconRocket />
          {t('upgradeLabel')}
        </Button>
        <Button type="outlined" id="intercom-chat" className={cx('sm:w-40', buttonClassName)}>
          {t('contactUsLabel')}
        </Button>
      </div>
    </>
  )
}

export default ActionButtons
