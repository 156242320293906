import React, { useEffect, useMemo } from 'react'
import {
  Route,
  Navigate,
  Outlet,
  createRoutesFromElements,
  useOutlet,
  createBrowserRouter,
  useLocation,
  useParams,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'

import ErrorFallback from '@components/ErrorBoundary/ErrorBoundaryFallback'
import Layout from '@components/Layout'
import Loading from '@components/Loading'

import ContactLayout from '@pages/Accounting/Contacts/components/ContactLayout'
import ContactListLayout from '@pages/Accounting/Contacts/components/ContactListLayout'
import TeamLayout from '@pages/Team/components/Layout'
import Paywall from '@pages/Paywall'

import routes from '@src/Routes/routes'

import useIsAdmin from '@helpers/hooks/useIsAdmin'
import Auth from '@helpers/AuthRoute'
import lazy from '@helpers/lazy'

import { useGetMyAccount } from '@queries/Account'
import { Role } from '@type/common'
import { useGetShowOrgs } from '@helpers/hooks/useGetShowOrgs'
import CostCenterList from '@src/pages/Accounting/CostCenter/List'
import CostCenterForm from '@src/pages/Accounting/CostCenter/Form'
import CostCenterDetails from '@src/pages/Accounting/CostCenter/Details'
import CostCenterDetailsBudget from '@src/pages/Accounting/CostCenter/Details/components/BudgetTab'

import NotFound from '@pages/NotFound'
import ReportsList from '@pages/Reports/List'
import AIReports from '@pages/Reports/List/AIReports'
import ManualReports from '@pages/Reports/List/ManualReports'
import ReportForm from '@pages/Reports/ReportForm'
import { useGetShowAIReports } from '@helpers/hooks/useGetShowAIReports'
import { pageViewEvents, sendEvent } from '@src/helpers/hooks/useAmplitude'

const LoginPage = lazy(() => import('@src/pages/Authorization/Auth'), 'LoginPage')
const SettingsPage = lazy(() => import('@src/pages/Settings'), 'SettingsPage')

const ApprovalRequestsPage = lazy(() => import('@src/pages/ApprovalRequests/RequestsList'), 'ApprovalRequestsPage')
const ApprovalRequestsDetailPage = lazy(
  () => import('@src/pages/ApprovalRequests/RequestDetails'),
  'ApprovalRequestsDetailPage',
)
const ApprovalRequestsForm = lazy(
  () => import('@src/pages/ApprovalRequests/RequestForm/CustomForm'),
  'ApprovalRequestsForm',
)
const EmailApprove = lazy(() => import('@src/pages/ApprovalRequests/EmailApprove'), 'EmailApprove')

const IntegrationZapierPage = lazy(() => import('@src/pages/Settings/IntegrationZapierPage'), 'IntegrationZapierPage')

const Billing = lazy(() => import('@src/pages/Billing'), 'Billing')

const Onboarding = lazy(() => import('@pages/Onboarding'), 'Onboarding')
const Dashboard = lazy(() => import('@pages/Dashboard'), 'Dashboard')

const PipelineBuilder = lazy(() => import('@pages/Pipelines/Builder'), 'PipelineBuilder')
const PipelinesList = lazy(() => import('@pages/Pipelines/PipelinesList'), 'PipelinesList')
const ProcessDetails = lazy(() => import('@pages/Pipelines/ProcessDetails'), 'ProcessDetails')
const ProcessesList = lazy(() => import('@pages/Pipelines/ProcessesList'), 'ProcessesList')

const File = lazy(() => import('@pages/File'), 'File')

const QuickbooksDisconnected = lazy(
  () => import('@src/vendors/quickbooks/QuickbooksDisconnected'),
  'QuickbooksDisconnected',
)
const QuickbooksConnection = lazy(() => import('@src/vendors/quickbooks/QuickbooksConnect'), 'QuickbooksConnection')

const OrganizationalStructureList = lazy(() => import('@src/pages/Team/Members/List'), 'OrganizationalStructureList')
const OrganizationalStructureMember = lazy(
  () => import('@src/pages/Team/Members/Member'),
  'OrganizationalStructureMember',
)
const TeamDepartments = lazy(() => import('@src/pages/Team/Departments'), 'TeamDepartments')
const TeamLocations = lazy(() => import('@src/pages/Team/Locations'), 'TeamLocations')

const Mailbox = lazy(() => import('@pages/Mailbox'), 'Mailbox')

// const RequestorUpgradePopup = lazy(() => import('@src/pages/Billing/RequestorUpgradePopup'))

const VendorsList = lazy(() => import('@src/pages/Accounting/Contacts/ContactsList'), 'VendorsList')
const VendorForm = lazy(() => import('@src/pages/Accounting/Contacts/ContactForm'), 'VendorForm')

const InvoicesList = lazy(() => import('@src/pages/Accounting/Invoices/List'), 'InvoicesList')
const InvoiceDetails = lazy(() => import('@src/pages/Accounting/Invoices/Details'), 'InvoiceDetails')

const FinanceSettingsLayout = lazy(
  () => import('@src/pages/Accounting/FinanceSettings/components/Layout'),
  'FinanceSettingsLayout',
)
const AccountsList = lazy(() => import('@src/pages/Accounting/Accounts/AccountsList'), 'AccountsList')
const CategoriesList = lazy(() => import('@src/pages/Accounting/Accounts/CategoriesList'), 'CategoriesList')
const CompanyDetails = lazy(() => import('@src/pages/Accounting/FinanceSettings/CompanyDetails'), 'CompanyDetails')
const TaxList = lazy(() => import('@src/pages/Accounting/Taxes/TaxList'), 'TaxList')

const OrganisationsList = lazy(
  () => import('@src/pages/Accounting/Organizations/OrganizationsList'),
  'OrganisationsList',
)
const OrganizationForm = lazy(() => import('@src/pages/Accounting/Organizations/OrganizationForm'), 'OrganizationForm')

const VendorContractsList = lazy(
  () => import('@src/pages/Accounting/Contacts/Contracts/ContractsList'),
  'VendorContractsList',
)
const ContractsList = lazy(() => import('@src/pages/Accounting/Contracts/List'), 'ContractsList')
const ContractForm = lazy(() => import('@src/pages/Accounting/Contacts/Contracts/ContractForm'), 'ContractForm')

const PublicForm = lazy(() => import('@src/pages/ApprovalRequests/RequestForm/PublicForm'), 'PublicForm')

const InstallTeamsGuide = lazy(() => import('@pages/InstallTeamsGuide'), 'InstallTeamsGuide')

const Tasks = lazy(() => import('@pages/Tasks/List'), 'Tasks')

const PurchaseOrdersList = lazy(() => import('@src/pages/Accounting/PurchaseOrders/List'), 'PurchaseOrdersList')
const PurchaseOrderDetails = lazy(() => import('@src/pages/Accounting/PurchaseOrders/Details'), 'PurchaseOrderDetails')

const QuotesList = lazy(() => import('@src/pages/Accounting/Quotes/List'), 'QuotesList')
const QuoteDetails = lazy(() => import('@src/pages/Accounting/Quotes/Details'), 'QuoteDetails')

const AuthLayout = Auth(Layout)

const LoadingContainer = () => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <React.Suspense fallback={<Loading loading />}>
        <Outlet />
      </React.Suspense>
    </Sentry.ErrorBoundary>
  )
}

export const ProtectedRoute: React.FC<
  React.PropsWithChildren<{
    adminOnly?: boolean
    userOnly?: boolean
    onlyWithOrgsAvailable?: boolean
    checkWithPrivacySettings?: boolean
    onlyWithReportsAvailable?: boolean
  }>
> = ({ children, adminOnly, userOnly, onlyWithOrgsAvailable, checkWithPrivacySettings, onlyWithReportsAvailable }) => {
  const { data: user } = useGetMyAccount()
  const isAdmin = useIsAdmin()
  const isOrgShown = useGetShowOrgs()
  const showReports = useGetShowAIReports()

  const outlet = useOutlet()

  const showOrgStructure = useMemo(() => {
    return user?.team?.settings?.orgstructure_visibility === Role.admin.toLowerCase() ? isAdmin : true
  }, [user])

  const shouldNavigateToDashboard =
    (userOnly && isAdmin) ||
    (adminOnly && !isAdmin) ||
    (onlyWithOrgsAvailable && !isOrgShown) ||
    (onlyWithReportsAvailable && !showReports) ||
    (checkWithPrivacySettings && !showOrgStructure)

  if (shouldNavigateToDashboard) {
    return <Navigate to={routes.private.dashboard} />
  }

  return <>{children || outlet}</>
}

const AmplitudeWrapper = () => {
  const location = useLocation()
  const params = useParams<Record<string, string>>()

  useEffect(() => {
    let path = location.pathname
    let event = pageViewEvents[path]
    if (!event) {
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          path = path.replace(value, `:${key}`)
        }
      })
      event = pageViewEvents[path]
      if (path === routes.private.paywall) {
        event = event += `_${params.feature?.replace(/-/g, '_')}`
      }
    }
    if (event) {
      sendEvent(event)
    }
  }, [location])

  return <Outlet />
}

const Router = createRoutesFromElements(
  <Route element={<AmplitudeWrapper />}>
    <Route element={<LoadingContainer />}>
      <Route path={routes.public.signup} element={<LoginPage />} />
      <Route path={routes.public.login} element={<LoginPage />} />
      <Route path={routes.public.activate} element={<LoginPage />} />
      <Route path={routes.public.forgotPassword} element={<LoginPage />} />
      <Route path={routes.public.approvalRequestApproveReject} element={<EmailApprove />} />
      <Route path={routes.public.approvalRequestApproveRejectPublic} element={<EmailApprove />} />
      <Route path={routes.public.quickbooksDisconnected} element={<QuickbooksDisconnected />} />
      <Route path={routes.public.publicForm} element={<PublicForm />} />
      <Route path={routes.public.installTeams} element={<InstallTeamsGuide />} />
      <Route path={routes.public.notFound} element={<NotFound />} />
    </Route>

    <Route path="/" element={<AuthLayout />}>
      <Route path={routes.private.onboarding} element={<Onboarding />} />
      <Route path={routes.private.paywall} element={<Paywall />} />

      <Route
        path={routes.private.billing}
        element={
          <ProtectedRoute adminOnly>
            <Billing />
          </ProtectedRoute>
        }
      />

      <Route element={<ProtectedRoute />}>
        <Route path={routes.private.file} element={<File />} />
        <Route path={routes.private['']} element={<Dashboard />} />
        <Route path={routes.private.dashboard} element={<Dashboard />} />
        <Route path={routes.private.settings} element={<SettingsPage />}>
          <Route index path={routes.private.settingsRouted} element={<SettingsPage />} />
        </Route>
        <Route
          path={routes.private.organizationalStructureById}
          element={
            <ProtectedRoute checkWithPrivacySettings>
              <OrganizationalStructureMember />
            </ProtectedRoute>
          }
        />
        <Route element={<TeamLayout />}>
          <Route
            path={routes.private.organizationalStructure}
            element={
              <ProtectedRoute checkWithPrivacySettings>
                <OrganizationalStructureList />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.private.teamDepartments}
            element={
              <ProtectedRoute adminOnly checkWithPrivacySettings>
                <TeamDepartments />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.private.teamDepartmentById}
            element={
              <ProtectedRoute adminOnly checkWithPrivacySettings>
                <TeamDepartments />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.private.teamLocations}
            element={
              <ProtectedRoute adminOnly checkWithPrivacySettings>
                <TeamLocations />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.private.teamLocationById}
            element={
              <ProtectedRoute adminOnly checkWithPrivacySettings>
                <TeamLocations />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={routes.private.integrationsZapier} element={<IntegrationZapierPage />} />
        <Route
          path={routes.private.quickbooksConnect}
          element={
            <ProtectedRoute adminOnly>
              <QuickbooksConnection />
            </ProtectedRoute>
          }
        />
        <Route path={routes.private.approvalRequests} element={<ApprovalRequestsPage />} />
        <Route path={routes.private.approvalRequest} element={<ApprovalRequestsForm />} />
        <Route path={routes.private.approvalRequestById} element={<ApprovalRequestsForm />} />
        <Route path={routes.private.approvalRequestEditById} element={<ApprovalRequestsForm />} />
        <Route path={routes.private.approvalRequestsById} element={<ApprovalRequestsDetailPage />} />
        <Route path={routes.private.approvalRequestsByIdAction} element={<ApprovalRequestsDetailPage />} />
        <Route path={routes.private.approvalRequestsByIdUuidAction} element={<ApprovalRequestsDetailPage />} />
        <Route
          path={routes.private.pipelineById}
          element={
            <ProtectedRoute adminOnly>
              <PipelineBuilder />
            </ProtectedRoute>
          }
        />
        <Route path={routes.private.processes} element={<ProcessesList />} />
        <Route path={routes.private.pipelineProcesses} element={<ProcessesList />} />
        <Route path={routes.private.pipelines} element={<PipelinesList />} />
        <Route path={routes.private.pipelineProcessesById} element={<ProcessDetails />} />
        <Route path={routes.private.pipelineApprovalRequest} element={<ApprovalRequestsForm />} />
        <Route path={routes.private.pipelineProcessApprovalRequest} element={<ApprovalRequestsForm />} />
        <Route path={routes.private.installTeams} element={<InstallTeamsGuide />} />

        <Route
          path={routes.private.mailbox}
          element={
            <ProtectedRoute adminOnly>
              <Mailbox />
            </ProtectedRoute>
          }
        />

        <Route path={routes.private.tasks} element={<Tasks />} />
        <Route path={routes.private.taskBoards} element={<Tasks />} />
        <Route path={routes.private.tasksBoardNewTask} element={<Tasks />} />
        <Route path={routes.private.taskById} element={<Tasks />} />

        <Route element={<ReportsList />}>
          <Route path={routes.private.reports} element={<ManualReports />} />

          <Route
            path={routes.private.aiReports}
            element={
              <ProtectedRoute onlyWithReportsAvailable adminOnly>
                <AIReports />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={routes.private.createReport} element={<ReportForm />} />
        <Route path={routes.private.reportById} element={<ReportForm />} />
      </Route>

      {/* orgs */}
      <Route
        path={routes.private.organizations}
        element={
          <ProtectedRoute adminOnly onlyWithOrgsAvailable>
            <OrganisationsList />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.private.organization}
        element={
          <ProtectedRoute adminOnly onlyWithOrgsAvailable>
            <OrganizationForm />
          </ProtectedRoute>
        }
      />

      {/* contacts */}
      <Route element={<ContactListLayout />}>
        <Route
          path={routes.private.contacts}
          element={
            <ProtectedRoute adminOnly>
              <VendorsList />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.private.contactsList}
          element={
            <ProtectedRoute adminOnly>
              <VendorsList />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route element={<ContactLayout />}>
        <Route
          path={routes.private.contact}
          element={
            <ProtectedRoute adminOnly>
              <VendorForm />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.private.contactContracts}
          element={
            <ProtectedRoute adminOnly>
              <VendorContractsList />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path={routes.private.contactContract}
        element={
          <ProtectedRoute adminOnly>
            <ContractForm />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.private.contracts}
        element={
          <ProtectedRoute adminOnly>
            <ContractsList />
          </ProtectedRoute>
        }
      />

      {/* invoices */}
      <Route path={routes.private.invoices} element={<InvoicesList />} />
      <Route path={routes.private.invoiceById} element={<InvoiceDetails />} />
      <Route path={routes.private.bills} element={<InvoicesList />} />
      <Route path={routes.private.billById} element={<InvoiceDetails />} />

      {/* pos */}
      <Route path={routes.private.purchaseOrders} element={<PurchaseOrdersList />} />
      <Route path={routes.private.purchaseOrdersById} element={<PurchaseOrderDetails />} />
      {/* <Route path={routes.private.invoiceById} element={<InvoiceDetails />} /> */}

      {/* quotes */}
      <Route path={routes.private.quotes} element={<QuotesList />} />
      <Route path={routes.private.quoteById} element={<QuoteDetails />} />

      {/* finance settings */}
      <Route element={<FinanceSettingsLayout />}>
        <Route path={routes.private.financeSettings} element={<Navigate to={routes.private.accounts} />} />
        <Route
          path={routes.private.accounts}
          element={
            <ProtectedRoute adminOnly>
              <AccountsList />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.private.accountById}
          element={
            <ProtectedRoute adminOnly>
              <AccountsList />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.private.taxes}
          element={
            <ProtectedRoute adminOnly>
              <TaxList />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.private.taxById}
          element={
            <ProtectedRoute adminOnly>
              <TaxList />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.private.accountCategories}
          element={
            <ProtectedRoute adminOnly>
              <CategoriesList />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.private.accountCategoryById}
          element={
            <ProtectedRoute adminOnly>
              <CategoriesList />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.private.companyDetails}
          element={
            <ProtectedRoute adminOnly>
              <CompanyDetails />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* cost center */}
      <Route
        path={routes.private.costCenter}
        element={
          <ProtectedRoute adminOnly>
            <CostCenterList />
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.private.costCenterById}
        element={
          <ProtectedRoute adminOnly>
            <CostCenterDetails />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="budget" replace />} />
        <Route path={routes.private.costCenterBudget} element={<CostCenterDetailsBudget />} />
        {/* <Route path={routes.private.costCenterBreakdown} element={<CostCenterDetailsBreakdown />} /> */}
      </Route>

      <Route
        path={routes.private.costCenterEditById}
        element={
          <ProtectedRoute adminOnly>
            <CostCenterForm />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route path="*" element={<Navigate to="/404" />} />
  </Route>,
)

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
const router = sentryCreateBrowserRouter(Router)

export default router
