import { format } from 'date-fns'

import { BudgetAllocationRequest, CostCenterBodyRequest } from '@models/CostCenters'
import { BudgetAllocation, Form } from '../types'
import { BudgetFrequency, DeductedSumType } from '@type/budget'

export class RequestBody implements CostCenterBodyRequest {
  accounting_category?: string | null | undefined
  budget_allocations: BudgetAllocationRequest[]
  budget_frequency: BudgetFrequency
  code: string
  deducted_sum: DeductedSumType
  name: string
  owner: number
  starting_day: number | null

  private getBudget = (budget: BudgetAllocation): BudgetAllocationRequest => {
    return {
      name: budget.name,
      start_date: format(budget.startDate, 'yyyy-MM-dd'),
      end_date: format(budget.endDate, 'yyyy-MM-dd'),
      amount: (budget.amount || 0) * 100,
      accounting_category: budget.category || null,
    }
  }

  private getBudgetAllocations = (data: Form) => {
    const allocations = data.budgetAllocations?.map((budget) => {
      let amount = budget.amount
      if (budget.isUncategorized) {
        amount =
          data.budgetAllocations.reduce((total, currentBudget) => {
            if (
              format(budget.startDate, 'yyyy-MM-dd') === format(currentBudget.startDate, 'yyyy-MM-dd') &&
              !currentBudget.isUncategorized
            ) {
              return total - currentBudget.amount * 100
            }
            return total
          }, budget.amount * 100) / 100
      }
      return this.getBudget({ ...budget, amount })
    })

    return allocations
  }

  constructor(data: Form) {
    this.name = data.name
    this.code = data.code
    this.owner = data.owner as number
    this.deducted_sum = data.deductedSum
    this.budget_frequency = data.frequency
    this.starting_day = data.startDay
    this.budget_allocations = this.getBudgetAllocations(data)
  }
}
