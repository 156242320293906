import React, { useMemo } from 'react'
import i18n from '@src/i18n'
import { generatePath } from 'react-router-dom'

import routes from '@src/Routes/routes'
import { CALENDLY_URL, GUIDES_LINK, YOUTUBE_PLAYLISTS } from '@src/constants'
import {
  IconCircleCheck,
  IconListDetails,
  IconBolt,
  IconBuildingWarehouse,
  IconUsers,
  IconHome,
  IconUsersGroup,
  IconReceipt,
  IconTruckDelivery,
  IconFileInvoice,
  IconCoins,
  IconMail,
  TablerIconsProps,
  IconFileDollar,
  IconChartInfographic,
  IconNotes,
} from '@tabler/icons-react'

import { ContactParty } from '@models/ApproveitAccounting'
import { Role } from '@type/common'
import { Feature } from '@type/features'

export interface NavItem {
  to?: string
  label: string
  icon: React.FC<TablerIconsProps>
  show?: Role[]
  external?: boolean
  onClick?: () => void
  roleVisibilityPermission?: boolean
  feature?: Feature
  isCollapsed?: boolean
  disabled?: boolean
  amplitude?: string
}

// LEFT ITEMS

export const NAV_ITEMS = [
  {
    to: routes.private.dashboard,
    icon: IconHome,
    label: i18n.t('common:navigation.dashboard'),
  },
  {
    to: routes.private.reports,
    icon: IconChartInfographic,
    label: i18n.t('common:navigation.reports'),
  },
  {
    to: routes.private.pipelines,
    icon: IconBolt,
    label: i18n.t('common:navigation.workflows'),
  },
  {
    to: routes.private.approvalRequests,
    icon: IconCircleCheck,
    label: i18n.t('common:navigation.approvalRequests'),
  },
  {
    to: routes.private.tasks,
    icon: IconListDetails,
    label: i18n.t('common:navigation.tasks'),
  },
  {
    to: routes.private.mailbox,
    icon: IconMail,
    label: i18n.t('common:navigation.mailbox'),
    show: [Role.admin],
    feature: Feature.mailbox,
  },
  {
    to: routes.private.organizationalStructure,
    icon: IconUsersGroup,
    label: i18n.t('common:navigation.team'),
    roleVisibilityPermission: true,
  },
  {
    to: routes.private.costCenter,
    icon: IconCoins,
    label: i18n.t('common:navigation.costCenters'),
    show: [Role.admin],
    feature: Feature.costCenters,
  },
]

export const AR_ITEMS = [
  {
    to: routes.private.invoices,
    icon: IconFileInvoice,
    label: i18n.t('common:navigation.invoices'),
  },
  {
    to: routes.private.quotes,
    icon: IconFileDollar,
    label: i18n.t('common:navigation.quotes'),
  },
  {
    to: generatePath(routes.private.contacts, { contactParty: ContactParty.customer }),
    icon: IconUsers,
    label: i18n.t('common:navigation.customers'),
    show: [Role.admin],
  },
]
export const AP_ITEMS = [
  {
    to: routes.private.bills,
    icon: IconReceipt,
    label: i18n.t('common:navigation.bills'),
  },
  {
    to: routes.private.purchaseOrders,
    icon: IconTruckDelivery,
    label: i18n.t('common:navigation.purchaseOrders'),
  },
  {
    to: generatePath(routes.private.contacts, { contactParty: ContactParty.vendor }),
    icon: IconBuildingWarehouse,
    label: i18n.t('common:navigation.vendors'),
    show: [Role.admin],
  },
]
export const CONTRACT_ITEMS = [
  {
    to: routes.private.contracts,
    icon: IconNotes,
    label: i18n.t('common:navigation.contracts'),
    show: [Role.admin],
    feature: Feature.contractManagement,
  },
]

export const ORGANIZATION_MENU = [
  {
    to: routes.private.accounts,
    icon: IconBuildingWarehouse,
    label: i18n.t('common:navigation.accounts'),
    show: [Role.admin],
  },
  {
    to: routes.private.accountCategories,
    icon: IconBuildingWarehouse,
    label: i18n.t('common:navigation.categories'),
    show: [Role.admin],
  },
  {
    to: routes.private.taxes,
    icon: IconBuildingWarehouse,
    label: i18n.t('common:navigation.taxes'),
    show: [Role.admin],
  },
  {
    to: routes.private.companyDetails,
    icon: IconBuildingWarehouse,
    label: i18n.t('common:navigation.companyDetails'),
    show: [Role.admin],
  },
]
// HEAD ITEMS

export const QUICK_ACTIONS_ITEMS = [
  {
    to: routes.private.approvalRequest,
    label: i18n.t('common:navigation.approvalRequest'),
    amplitude: 'create_request_quick_action',
    icon: IconCircleCheck,
  },
  {
    to: routes.private.taskBoards,
    label: i18n.t('common:navigation.task'),
    amplitude: 'create_task_quick_action',
    icon: IconListDetails,
  },
  {
    to: generatePath(routes.private.pipelineById, { id: 'new' }),
    label: i18n.t('common:navigation.workflow'),
    amplitude: 'create_workflow_quick_action',
    icon: IconBolt,
    show: [Role.admin],
  },
  {
    to: generatePath(routes.private.contact, { id: 'new', contactParty: ContactParty.vendor }),
    label: i18n.t('common:navigation.vendor'),
    amplitude: 'create_vendor_quick_action',
    icon: IconBuildingWarehouse,
    show: [Role.admin],
  },
  {
    to: generatePath(routes.private.contact, { id: 'new', contactParty: ContactParty.customer }),
    label: i18n.t('common:navigation.customer'),
    amplitude: 'create_customer_quick_action',
    icon: IconUsers,
    show: [Role.admin],
  },
]

export const useGetResourceItems = (setShowSuggestFeatureModal: (show: boolean) => void) => {
  return useMemo(
    () => [
      { to: GUIDES_LINK, label: i18n.t('common:navigation.allGuides'), external: true },
      { to: YOUTUBE_PLAYLISTS, label: i18n.t('common:navigation.videoGuides'), external: true },
      { to: CALENDLY_URL, label: i18n.t('common:navigation.bookCall'), external: true },
      { label: i18n.t('common:navigation.suggestFeature'), onClick: () => setShowSuggestFeatureModal(true) },
    ],
    [setShowSuggestFeatureModal],
  )
}
