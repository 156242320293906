import React, { useEffect, useRef } from 'react'
import cx from 'clsx'
import { MenuItem, MenuButton, ControlledMenu, useMenuState, useClick } from '@szhsin/react-menu'
import { NavLink, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ContentLock from '@components/ContentLock'
import OrganizationMenu from '../OrganizationMenu'
import Divider from '@components/Divider'
import { IconMenu2, IconX, TablerIconsProps } from '@tabler/icons-react'

import { NAV_ITEMS, AP_ITEMS, AR_ITEMS, CONTRACT_ITEMS, NavItem } from '../menuItems'
import { useGetAccount } from '@helpers/hooks/useGetAccount'
import { filterNavItems } from '../utils'
import routes from '@src/Routes/routes'
import { useGetShowCustomers } from '@helpers/hooks/useGetShowCustomers'
import { useGetIsFeatureAvailable } from '@helpers/hooks/useGetHideWithPaywall'

import { ContactParty } from '@models/ApproveitAccounting'

import styles from './index.module.css'
import { twMerge } from 'tailwind-merge'
import { Feature } from '@src/types/features'

interface NavigationLinkProps extends NavItem {
  to?: string
  label: string
  icon: React.FC<TablerIconsProps>
  isDisabled?: boolean
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ to, feature, icon: Icon, label, isDisabled = false }) => {
  const { available, paywallPath } = useGetIsFeatureAvailable(feature)
  if (!to) return null

  return (
    <MenuItem className="px-3 py-0.5 bg-transparent">
      <NavLink
        className={({ isActive }) =>
          cx(
            styles.leftNavLink,
            (isActive || (window.location.pathname === '/' && to === '/dashboard')) && styles.active,
            'flex items-center justify-between px-2 group',
            isDisabled && styles.disabled,
          )
        }
        to={!available && paywallPath ? paywallPath : to}
      >
        <div className={cx('flex items-center gap-5', !available && 'opacity-60')}>
          <Icon className={styles.navIcon} stroke={1.5} />
          <span>{label}</span>
        </div>
        {!available && <ContentLock />}
      </NavLink>
    </MenuItem>
  )
}
const NavigationBlock = ({
  items,
  title,
  className,
  feature,
}: {
  items: NavItem[]
  title?: string
  className?: string
  feature?: Feature
}) => {
  const { available, paywallPath } = useGetIsFeatureAvailable(feature)
  return items.length ? (
    <div className={twMerge(cx('pt-1 flex flex-col text-nowrap', className))}>
      {title && (
        <div
          className={cx(
            'flex justify-between items-center pl-5 pr-5 text-sm font-medium text-grey_1 transition-opacity duration-400 mb-1',
          )}
        >
          {title}
          {!available && paywallPath && <ContentLock to={paywallPath} />}
        </div>
      )}
      {items.map((item) => (
        <NavigationLink key={item.to} {...item} isDisabled={!available} />
      ))}
    </div>
  ) : null
}
const BurgerMenu: React.FC<{ isDisabled?: boolean }> = ({ isDisabled }) => {
  const { t } = useTranslation('common', { keyPrefix: 'navigation' })

  const menuRef = useRef(null)
  const [menuState, toggleMenu] = useMenuState({ transition: { open: true } })

  const showCustomers = useGetShowCustomers()
  const account = useGetAccount()

  const anchorProps = useClick(menuState.state, toggleMenu)

  useEffect(() => {
    const container = document.getElementById('container')

    if (container) {
      if (menuState.state === 'open') {
        document.body.style.overflow = 'hidden'
        container.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
        container.style.overflow = 'auto'
      }
    }

    return () => {
      document.body.style.overflow = 'auto'
      if (container) {
        container.style.overflow = 'auto'
      }
    }
  }, [menuState.state])

  return (
    <>
      <MenuButton ref={menuRef} {...anchorProps}>
        <div className="relative group">
          <div
            className={cx(
              styles.burgerMenuButton,
              'group-hover:opacity-10 group-active:opacity-20 group-active:outline',
            )}
          />
          <IconMenu2 className="relative z-10 h-6 w-6 text-black" stroke={1.5} />
        </div>
      </MenuButton>
      <ControlledMenu menuClassName="!-top-52" {...menuState} anchorRef={menuRef} onClose={() => toggleMenu(false)}>
        <div
          className={styles.burgerMenu}
          style={{
            opacity: menuState.state === 'open' ? 1 : 0,
            bottom: menuState.state === 'open' ? 0 : '-100vh',
          }}
        >
          <div
            id="navigation"
            className="flex items-center justify-between px-4 h-12 bg-white_smoke z-10 relative border-t xs:hidden"
          >
            <MenuItem className={styles.companyLogoWrap}>
              <NavLink to={routes.private.dashboard}>
                <img className={styles.companyLogo} src="/logo.svg" />
                Approveit
              </NavLink>
            </MenuItem>
            <IconX size={1} className="h-5 w-5 cursor-pointer text-brand_primary" onClick={() => toggleMenu(false)} />
          </div>
          <OrganizationMenu isBurgerMenu />
          <Divider className="my-1" />
          {account &&
            filterNavItems(NAV_ITEMS, account).map((item) => (
              <NavigationLink key={item.to} {...item} isDisabled={isDisabled} />
            ))}

          <NavigationBlock
            items={filterNavItems(AP_ITEMS, account, {
              [generatePath(routes.private.contacts, { contactParty: ContactParty.vendor })]: showCustomers,
            })}
            title={t('accountsPayable')}
            feature={Feature.accountsPayable}
          />
          <NavigationBlock
            items={filterNavItems(AR_ITEMS, account, {
              [generatePath(routes.private.contacts, { contactParty: ContactParty.customer })]: showCustomers,
            })}
            title={t('accountsReceivable')}
            feature={Feature.accountsPayable}
          />
          {account &&
            filterNavItems(CONTRACT_ITEMS, account).map((item) => (
              <NavigationLink key={item.to} {...item} isDisabled={isDisabled} />
            ))}
        </div>
      </ControlledMenu>
    </>
  )
}

export default BurgerMenu
